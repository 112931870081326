import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Img from "gatsby-image";
import { theme } from "../styles";
import styled from '@emotion/styled'
import { Wrapper, Header } from '../components'
import website from '../../config/website'
import SEO from '../components/SEO'

import {
  CatalogItem,
  CatalogHeader,
} from '../components'
import {
  CatalogWrapper
} from '../templates/catalogItemStyle'

const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2.4rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;

const Head = styled.div`
  position: relative;
  margin-bottom: 1.35rem;
`;

const Hero = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > div {
    width: 50%;
    &:first-of-type {
      padding-right: 1rem;
    }
    &:last-of-type {
      padding-left: 1rem;
    }
    p {
      margin-bottom: 1.35rem;
      font-size: 1.1rem;
      color: #6d6d6d;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    > div {
      width: 100%;
      &:first-of-type {
        padding-right: 0;
      }
      &:last-of-type {
        padding-left: 0;
      }
      p {
        &:first-of-type {
          padding-top: 1.35rem;
        }
      }
    }
  }
`;

const Banner = styled.div`
  position:relative;
  margin-bottom: 2rem;
`;

const Gallery = styled.div`
  position: relative;
`;

const SeptembreMMXXII = ({ 
  pageContext: { locale }, 
  location, 
  data
}, props) => {
  const context = { slug: "exhibition", parent: "" };
  const document = [data.artwork];
  
  return (
    <React.Fragment>
      <Helmet title={`Disch Rémy - La lettre`}>
        <link rel="stylesheet" type="text/css" href="https://unpkg.com/xzoom/dist/xzoom.css" media="all" />
      </Helmet>
      <CatalogHeader {...context} letter />
      {/* <Header {...context} /> */}
      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <Head>
            <Img
              fixed={data.banner.childImageSharp.fixed}
              placeholderStyle={{
                filter: "grayscale(100%) contrast(100%)",
                imageRendering: "pixelated",
                opacity: 0,
              }}
            />
            <Hero style={{ position: 'absolute', bottom: 0, left: '1rem' }}>
              <div style={{ width: '100%' }}>
                <h3 style={{ color: '#fff', opacity: .8 }}>
                  par <strong>David Braud</strong><br />
                  <small>Septembre 2022</small><br />
                </h3>
              </div>
            </Hero>
          </Head>
          <Hero>
            <div style={{ width: '100%', paddingTop: '2rem', paddingLeft: 0, paddingRight: 0 }}>
              <hr />
              <p style={{ paddingTop: 0 }}>
                L'été a fait mûrir les pommes de terre et les discussions dans son jardin.
                <br />L'artiste résiste et survit, porté par ce qui le traverse.
                <br />D'aucuns diront que c'est un choix...
              </p>
              <p style={{  }}>
                Mais, bien que personne ne soit assez grand pour une telle vocation, 
                <br />questionner l'instant est une nécessité. 
                <br />L'art nous sauve.
              </p>
              <p style={{  }}>
                Il nous invite à vivre une aventure aussi merveilleuse qu'exigeante.
                <br />À ne pas mourir de la vérité.
              </p>
              <p style={{  }}>
                C'est en tant qu'agent de Rémy Disch que je suis honoré de vous présenter ce qui suit !
              </p>
              <hr />
              <h3 style={{ marginTop: '5rem' }}><strong>"Le jour du silence"</strong></h3>
              <p>
                Comme vous le savez, Rémy a quitté Paris depuis un an et entame une nouvelle période de son travail.<br />
                C'est ainsi, enlacé par le mouvement de la forêt, qu'il a signé cet été <strong>"Le jour du silence"</strong>. <br />Un tournant important dans sa peinture.<br />
              </p>
              <p>
                <strong>"Le jour du silence"</strong> est une oeuvre grand format (130x162cm). 
                <br />Si vous souhaitez avoir plus d'informations n'hésitez pas à me contacter.
              </p>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>

      <CatalogWrapper className="fullscreen description letter">
        <CatalogItem 
          key={`page-artwork-${1}`} 
          currentArtwork={{ document: document}}
          count={1}
          elementId={`artwork-1`}
          id={1}
          zoomPosition={'left'}
          hideprice
          hd
        />
      </CatalogWrapper>

      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <Hero>
            <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
              <h3>Exposition collective <strong>"Amitiés"</strong></h3>
              <p>
                Nous vous en avions parlé au fil des deux dernières lettres, une exposition collective en hommage à Mme et M. Bansard aura lieu <strong>du 23 au 28 septembre</strong>.
                Nous avions organisé une <a className="regular-link" href="https://www.dischr.com/murmures" target="_blank">exposition dans la galerie de Mme et M. Bansard</a> en 2020.
              </p>
              <p>
                Cette exposition "Amitiés" prendra place dans la salle Béatrice Hodent de Broutelles de la mairie du 7e.
                <br /><strong>Adresse : 116 rue de Grenelle, Paris 7.</strong>
              </p>
              <p>
                <a className="regular-link" href={`https://www.dischr.com/les-2-soeurs/`} target="_blank">"Les deux sœurs"</a>, une œuvre de 2019 y sera exposée.
                <br /><strong>Le vernissage de cette exposition aura lieu le 22 septembre à 18h</strong>, Rémy et moi-même serons ravis de vous y trouver et d'échanger avec vous à cette occasion.
              </p>

              <h3 style={{ marginTop: '5rem' }}>Nouveau catalogue</h3>
              <p>
                En attendant le nouveau catalogue qui mettra entre autres l'accent sur les nouvelles œuvres grands formats,
                je vous rappelle que l'actuel est disponible à cette adresse : 
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog`} target="_blank">https://www.dischr.com/catalog</a>{` `}
                et que je me tiens à votre disposition si vous souhaitez acquérir une œuvre.
              </p>

              <h3 style={{ marginTop: '5rem' }}>Acquérir des oeuvres</h3>
              <p>
                Vous pouvez également retrouver Rémy sur le site <a className="regular-link" href="https://www.artsper.com/es/artistes-contemporains/france/102408/remy-disch" target="_blank">Artsper</a> où, après avoir quitté Drouot depuis 1 an, il y présente
                2 œuvres aux côtés des plus grands artistes contemporains mondiaux.
              </p>
              <p>
                Enfin, si vous souhaitez acquérir une œuvre à un prix plus abordable vous pouvez vous promener sur 
                {` `}<a className="regular-link" href={`https://www.dischr.com/shop`} target="_blank">la boutique du site</a>{` `}
                ou me contacter pour prendre connaissance des œuvres disponibles.
              </p>
              <p>
                En effet, je profite de ce moment pour vous dire que je dispose des œuvres encore disponibles de Rémy chez moi, 
                une bonne occasion de se rencontrer pour ceux d'entre vous qui habitent Paris et souhaiteraient acquérir une ou plusieurs oeuvres.
              </p>
              <p>
                <br /><br />Je vous souhaite à toutes et tous un bon été indien.
                <br />Au plaisir de vous rencontrer bientôt,
              </p>
              <p>
                David Braud<br />
                <span style={{ paddingTop: '1rem' }}>
                  ---
                  <br />(+33) 651 453 881
                  <br /><a className="regular-link" href="mailto: davidbraud@dischr.com">davidbraud@dischr.com</a>
                  <br /><a className="regular-link" href={`https://www.dischr.com`} target="_blank">dischr.com</a>
                </span>
              </p>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  );
};

export default SeptembreMMXXII;

export const pageQuery = graphql`
  query SeptembreMMXXIIPage {
    banner: file(name: {eq: "bandeau_lettre"}) {
      childImageSharp {
        fixed(width: 1000, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    artwork: prismicArtwork(uid: {eq: "le-jour-du-silence"}) {
      prismicId
      data {
        meta_description
        meta_title
        title {
          text
        }
        drouot_estimate
        catalog_fullscreen
        type
        available
        on_demand
        price
        item {
          alt
          localFile {
            childImageSharp {
              fixed(height: 800, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        cadre {
          alt
          localFile {
            childImageSharp {
              fixed(height: 800, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_hd {
          localFile {
            childImageSharp {
              original {
                height
                src
                width
              }
            }
          }
        }
        categories {
          ... on PrismicArtworkDataCategories {
            category {
              uid,
              document {
                data {
                  name
                }
              }
            }
          }
        }
        year {
          ... on PrismicArtworkDataYear {
            uid
          }
        }
      }
    }
  }
`
